import React, {useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { ReactFlowInstance, ReactFlowProvider} from 'reactflow';
import 'reactflow/dist/style.css';
import { LocationData, ProcessedData, nameToMne } from './utils';
import Panel from 'module/Panel';
import { css } from '@emotion/css';
import Box from 'module/Box';
import Antennas from './Antennas';
import ExxBox from './ExxBox';
import ExxCir from './ExxCir';
import PropThruster from './PropThruster';
import LivePlot from './LivePlot';
import Time3dFacade from './TimeFacade';
import MemoryBox from './MemoryBox';
import ExxBoxFdir from './ExxBoxFdir';
import ExxBoxFdirLarge from './ExxBoxFdirLarge';
import ScSketch from './ScSketch';
import { useTimeRangeContext } from './TimeRangeContext';
import SolarArray from './SolarArray';
import VModeBox from './VModeBox';
import PassIndicator from './PassIndicator';

type TelemetryDictionary = {
  [key: string]: TelemetryData;
};

type TelemetryData = {
  limit: any; // Replace 'any' with the actual type of limit
  telemetry: string; // Replace 'any' with the actual type of cnvValue
  unit: string; // Replace 'any' with the actual type f unit
  live: string;
  mne: string;
  spacecraft: string;
};

type Field = {
  name: string;
  type: string;
  values: any[]; // Replace 'any' with a more specific type if possible
};

type AppProps = {
  dbData: Field[];
  width: number;
  height: number;
  source: string;
  options: {
    text: string;                  // Corresponds to the 'addTextInput' option
    showSeriesCount: boolean;      // Corresponds to the 'addBooleanSwitch' option
    seriesCountSize: 'sm' | 'md' | 'lg';  // Corresponds to the 'addRadio' option
    X: number;           // Corresponds to the 'addNumberInput' option
    Y: number;
    Zoom: number;
  };
  groundData: Field[];
  locationData: LocationData;
  influxData: ProcessedData;
  // ... other props if there are any
};

const nodeTypes = {panel:Panel,solarArray: SolarArray ,passIndicator:PassIndicator,vmodeBox:VModeBox,scSketch:ScSketch,exxFdirBoxLarge:ExxBoxFdirLarge,exxFdirBox:ExxBoxFdir, memoryBox:MemoryBox,liveplot:LivePlot,timebox: Time3dFacade,propThruster:PropThruster,exxCir:ExxCir,exxbox:ExxBox,antennas:Antennas,box:Box}

const reactAppWrapper = css`

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.react-flow__node {
  z-index: 10 !important;
}

.react-flow__edges {
  z-index: 9 !important;
}

.react-flow__node-panel, .react-flow__node-rcsPanel, .react-flow__node-spoc{
  z-index: 5 !important;
}

.react-flow__node-solarCell{
  z-index: 6 !important;

}

.react-flow__edge-span{
  z-index: 3 !important;
}

  position: relative;
  background-color: #161616;
  color: #FFFFFF;
  font-size: 32px;
  font-family: monospace;
  box-sizing: border-box;
  text-align: center;
  display: flex;
`;

const App: React.FC<AppProps> = ({dbData, source, width, height, options, groundData, locationData, influxData}) => {
  
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const { guiValues } = useTimeRangeContext();
  // Use refs to always have the current values
  const pbTimeRef = useRef("0");
  const pbLoopRef = useRef(0);
  const [isDataReady, setIsDataReady] = useState(false);

  const onInit = useCallback((instance: ReactFlowInstance) => {
    setReactFlowInstance(instance);
  }, []);

  useEffect(() => {
    if (reactFlowInstance ) {
      reactFlowInstance.fitView();
    }
  }
  ,[width, height, reactFlowInstance]);

  function isWithinDifference(unixTimestamp: number) {

    // Convert the Unix timestamp to a JavaScript Date object
    const timestampDate = new Date(unixTimestamp * 1000);

    // Get the current time
    const currentDate = new Date();

    // Calculate the difference in seconds
    const timeDifference = (currentDate.getTime() - timestampDate.getTime()) / 1000;

    // Compare and return
    if (timeDifference <= 30) {
        return "rgb(72, 200, 44)";
    } else if (timeDifference <= 43200) { // 43200 seconds = 12 hours 
        return "#CA51EC";
    } else {
        return "rgb(68, 169, 241)"; //blue -- stale data
    }
  }

  function convertTimestampToPST(timestamp: number) {
    // Create a new Date object using the timestamp (in milliseconds)
    const date = new Date(timestamp * 1000);

    // To convert to PST, get the UTC time and subtract 8 hours for PST
    // Note: JavaScript Date object functions account for Daylight Saving Time changes
    const pstDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 
                           date.getUTCHours() - 8, date.getUTCMinutes(), date.getUTCSeconds());

    // Format the date and time in a readable format
    const formattedDate = pstDate.getFullYear() + '-' + 
                        ('0' + (pstDate.getMonth() + 1)).slice(-2) + '-' + 
                        ('0' + pstDate.getDate()).slice(-2) + ' ' + 
                        ('0' + pstDate.getHours()).slice(-2) + ':' + 
                        ('0' + pstDate.getMinutes()).slice(-2) + ':' + 
                        ('0' + pstDate.getSeconds()).slice(-2);

    return formattedDate;
}

  const getGroundData = useCallback(() => {
    // Safely get the other columns
    const stationColumn = groundData.find(column => column.name === "station");
    const scColumn = groundData.find(column => column.name === "scName");
    const tAosColumn = groundData.find(column => column.name === "tAos");
    const tLosColumn = groundData.find(column => column.name === "tLos");
    const trCodeColumn = groundData.find(column => column.name === "trCode");

    const station = stationColumn ? stationColumn.values[0] : null;
    let scName = scColumn ? scColumn.values[0] : null;
    let tAos = tAosColumn ? tAosColumn.values[0] : null;
    let tLos = tLosColumn ? tLosColumn.values[0] : null;
    let trCode = trCodeColumn ? trCodeColumn.values[0] : null;
  
    return {'SC':scName,'GS':station,  'AOS':convertTimestampToPST(tAos), 'LOS':convertTimestampToPST(tLos), 'Tr':trCode};
  },[groundData]);

  const groundList = getGroundData();

  const getDataFromSource = useCallback((mne: string) => {
    const mneColumn = dbData.find(column => column.name === "mne");
    if (!mneColumn) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne column not found
    }
  
    const mneIndex = mneColumn.values.indexOf(mne);
    if (mneIndex === -1) {
      return { limit: null, cnvValue: null, unit: null, live: "", mne: mne  }; // mne not found
    }
  
    // Safely get the other columns
    const limitColumn = dbData.find(column => column.name === "limit");
    const cnvValueColumn = dbData.find(column => column.name === "cnvValue");
    const unitColumn = dbData.find(column => column.name === "units");
    const timeColumn = dbData.find(column => column.name === "tInsert");
  
    const limit = limitColumn ? limitColumn.values[mneIndex] : null;
    let cnvValue = cnvValueColumn ? cnvValueColumn.values[mneIndex] : null;
    let unit = unitColumn ? unitColumn.values[mneIndex] : null;
    let live = timeColumn ? isWithinDifference(timeColumn.values[mneIndex]) : "";

    // Append the degree symbol tothe unit if the unit is 'C'
    if (unit === 'C' || unit === 'degC' || unit === 'deg C' || unit === 'Celsius') {
      unit = '\u00B0C';
    }else if(unit === 'packets'){
      unit = 'P'
    }else if(unit === 'sec'){
      unit = 'S'
    }else if(unit === 'None' || unit === 'na' || unit === 'N/A' ){
      unit = null
    }else if(unit === 'bool'){
      unit = 'B'
    }else if(unit === 'count' || unit === 'Counts' || unit === 'counts' || unit === 'Counter'){
      unit = 'CT'
    }else if(unit === 'rad/s'){
      unit = 'R/S'
    }else if(unit === 'Percent' || unit === 'percent' || unit === 'Percentage' || unit === 'percentage'){
      unit = '%'
    }
  
  // Special handling for 'WFI HV' and 'NFI HV'
  if (mne === 'cdh_version_fc_boot_count_time') {
    cnvValue = parseFloat(cnvValue).toExponential(2);
  } else if (cnvValue !== null && !isNaN(parseFloat(cnvValue))) {
    // Check if cnvValue is a number
    let numValue = parseFloat(cnvValue);

    // Convert to exponential notation if the integer part has more than 7 digits
    if (Math.abs(numValue) >= 10000000) {
      cnvValue = numValue.toExponential(2);
    } else if (cnvValue.includes('.')) {
      // It's a float, parse and keep 2 decimal places
      cnvValue = numValue.toFixed(2);
    }
  } 
  
    return { limit, cnvValue, unit, live, mne };
  },[dbData]);

  // Function to create the new dictionary
  const createTelemetryDictionary = useCallback(() => {
    const telemetryDict: Record<string, TelemetryData> = {};
  
    // Get the vehicle mode
    const vMode = getDataFromSource('state_spinand0_last_cmd_vehicle_mode_agg').cnvValue;
  
    Object.entries(nameToMne).forEach(([name, mne]) => {
      // Choose suffix based on vMode
      let suffix = vMode === "SAFE_MODE" ? "_sh" : "_agg";
      let newMne = mne + suffix;
      let data = getDataFromSource(newMne.toLowerCase());
  
      // If the cnvValue is null or empty, try with the regular mne
      if (!data.cnvValue) {
        newMne = mne; // Use the regular mnemonic
        data = getDataFromSource(newMne.toLowerCase());
      }
  
      telemetryDict[name] = {
        limit: data.limit,
        mne: data.mne,
        telemetry: data.cnvValue,
        unit: data.unit,
        live: data.live,
        spacecraft: source ? source : ""
      };
    });
  
    return telemetryDict;
  }, [getDataFromSource, source]);
  
  

  const telemetryDictionary = createTelemetryDictionary();

  const [nodes, setNodes] = useState([
    { id: 'Payload', type: 'panel', position:{x:150, y:50}, data:{'source':source,width:1600, height:1200,name:"Payload", hlink:"http://ops-display.ssl.berkeley.edu/d/c4d12ad6-f45b-44ac-8cd5-a755ea2c142a/escapade-payload-gui?orgId=1&refresh=5s&from=1721643945501&to=1721687145501"}},
    { id: 'CDH', type: 'panel', position:{x:150, y:1250}, data:{'source':source,width:1650, height:800,name:"CDH", hlink:"http://ops-display.ssl.berkeley.edu/d/edpzc6xclv4zkd/escapade-cdh?orgId=1&refresh=5s&from=1721644020030&to=1721687220030"}},
    { id: 'Comm', type: 'panel', position:{x:1800, y:1250}, data:{'source':source,width:2600, height:800,name:"Comm", hlink:"http://ops-display.ssl.berkeley.edu/d/adlq63qhlb9xca/escapade-comm?orgId=1&refresh=5s"}},
    { id: 'Prop', type: 'panel', position:{x:150, y:2050}, data:{'source':source,width:1200, height:650,name:"Prop", hlink:"http://ops-display.ssl.berkeley.edu/d/cdmiaiqo317nke/escapade-prop?orgId=1&refresh=5s"}},
    { id: 'EPS', type: 'panel', position:{x:1750, y:50}, data:{'source':source,width:2650, height:1200, name:"EPS", hlink:"http://ops-display.ssl.berkeley.edu/d/fdiy1s7uoj85cf/escapade-eps?orgId=1&refresh=5s&from=1721644177832&to=1721687377833"}},
    { id: 'GNC', type: 'panel', position:{x:1350, y:2050}, data:{'source':source,width:3050, height:650, name:"GNC", hlink:"http://ops-display.ssl.berkeley.edu/d/cdpvpsrck6k8wc/escapade-gnc?orgId=1&refresh=5s&from=1721644200155&to=1721687400155"}},
    { id: 'FDIR', type: 'panel', position:{x:150, y:2700}, data:{'source':source,width:4250, height:250, name:"TMon"}},

    { id: 'TMon OverVolt', type: 'exxFdirBox',parentNode:'FDIR', position:{x:3650, y:50}, data:{name:'Over Voltage',oriHeight:'150px', value:{"OV 1":{'dbData':telemetryDictionary['TMon 03 Triggered'],'fname':''}}}},
    { id: 'TMon UnderVolt', type: 'exxFdirBox',parentNode:'FDIR', position:{x:3050, y:50}, data:{name:'Under Voltage',oriHeight:'150px', value:{"UV 1":{'dbData':telemetryDictionary['TMon 05 Triggered'],'fname':''},"UV 2":{'dbData':telemetryDictionary['TMon 07 Triggered'],'fname':''},"UV 3":{'dbData':telemetryDictionary['TMon 08 Triggered'],'fname':''}}}}, 
    { id: 'TMon RW', type: 'exxFdirBox',parentNode:'FDIR', position:{x:2450, y:50}, data:{name:'Reaction Wheel',oriHeight:'150px', value:{"RW1 SL3":{'dbData':telemetryDictionary['TMon 28 Triggered'],'fname':''}, "RW2 SL3":{'dbData':telemetryDictionary['TMon 29 Triggered'],'fname':''}, "RW3 SL3":{'dbData':telemetryDictionary['TMon 30 Triggered'],'fname':''}, "RW4 SL3":{'dbData':telemetryDictionary['TMon 31 Triggered'],'fname':''}, "RW1 SL4":{'dbData':telemetryDictionary['TMon 32 Triggered'],'fname':''}, "RW2 SL4":{'dbData':telemetryDictionary['TMon 33 Triggered'],'fname':''}, "RW3 SL4":{'dbData':telemetryDictionary['TMon 34 Triggered'],'fname':''}, "RW4 SL4":{'dbData':telemetryDictionary['TMon 35 Triggered'],'fname':''}}}},
    { id: 'TMon Payload', type: 'exxFdirBox',parentNode:'FDIR', position:{x:1850, y:50}, data:{name:'Payload',oriHeight:'150px', value:{"Comm Err":{'dbData':telemetryDictionary['TMon 170 Triggered'],'fname':''},"Shutdown Req":{'dbData':telemetryDictionary['TMon 171 Triggered'],'fname':''},"OC":{'dbData':telemetryDictionary['TMon 172 Triggered'],'fname':'Payload Over Current'},"FW OC":{'dbData':telemetryDictionary['TMon 173 Triggered'],'fname':'Filter Wheel Over Current'}}}},
    { id: 'TMon IRU', type: 'exxFdirBox',parentNode:'FDIR', position:{x:1250, y:50}, data:{name:'IRU',oriHeight:'150px', value:{"SEE-SEU":{'dbData':telemetryDictionary['TMon 70 Triggered'],'fname':''},"OT/ME":{'dbData':telemetryDictionary['TMon 71 Triggered'],'fname':'Over Temperature Or Memory Error'}, "Locked Up":{'dbData':telemetryDictionary['TMon 72 Triggered'],'fname':'Tracker Locked Up'}}}},
    { id: 'TMon Star Tracker', type: 'exxFdirBox',parentNode:'FDIR', position:{x:650, y:50}, data:{name:'Star Tracker',oriHeight:'150px', value:{"Out of Solution":{'dbData':telemetryDictionary['TMon 64 Triggered'],'fname':''},"Locked Up":{'dbData':telemetryDictionary['TMon 65 Triggered'],'fname':''}, "Loss of Sync":{'dbData':telemetryDictionary['TMon 66 Triggered'],'fname':''}}}},
    { id: 'TMon ADCS', type: 'exxFdirBox',parentNode:'FDIR', position:{x:50, y:50}, data:{name:'ADCS',oriHeight:'150px', value:{"High Mom 1":{'dbData':telemetryDictionary['TMon 43 Triggered'],'fname':''}, "Att/Rate Err 1":{'dbData':telemetryDictionary['TMon 53 Triggered'],'fname':''},"Eph Err/Invalid Att":{'dbData':telemetryDictionary['TMon 56 Triggered'],'fname':''}}}},

    { id: 'PropThruster1', type: 'propThruster',parentNode:'Prop', position:{x:350, y:400}, data:{value:{'F1':{'dbData':telemetryDictionary['Thruster T1'],'fname':''},'F2':{'dbData':telemetryDictionary['Thruster T2'],'fname':''},'F3':{'dbData':telemetryDictionary['Thruster T3'],'fname':''},'F4':{'dbData':telemetryDictionary['Thruster T4'],'fname':''}}}},
    { id: 'PropTank', type: 'exxCir',parentNode:'Prop', position:{x:500, y:100}, data:{name:'GN2/N2H4', value:{'GN2 Temp':{'dbData':telemetryDictionary['Prop GN2 Temp'],'fname':''},'N2H4 Temp':{'dbData':telemetryDictionary['Prop N2H4 Temp'],'fname':''},}}},

    { id: 'RW4', type: 'exxbox',parentNode:'GNC', position:{x:2550, y:300}, data:{name:'Reaction Wheel 4',oriHeight:'150px', value:{'Power':{'dbData':telemetryDictionary['RW1 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW1 Speed/dir'],'fname':''}}}},
    { id: 'RW3', type: 'exxbox',parentNode:'GNC', position:{x:2550, y:50}, data:{name:'Reaction Wheel 3',oriHeight:'150px', value:{'Power':{'dbData':telemetryDictionary['RW2 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW2 Speed/dir'],'fname':''}}}},
    { id: 'RW2', type: 'exxbox',parentNode:'GNC', position:{x:2050, y:300}, data:{name:'Reaction Wheel 2',oriHeight:'150px', value:{'Power':{'dbData':telemetryDictionary['RW3 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW3 Speed/dir'],'fname':''}}}},
    { id: 'RW1', type: 'exxbox',parentNode:'GNC', position:{x:2050, y:50}, data:{name:'Reaction Wheel 1',oriHeight:'150px', value:{'Power':{'dbData':telemetryDictionary['RW4 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW4 Speed/dir'],'fname':''}}}},
    { id: 'St', type: 'exxbox',parentNode:'GNC', position:{x:1550, y:400}, data:{name:'Star Tracker',oriHeight:'150px', value:{"Pwr":{'dbData':telemetryDictionary['St Pwr'],'fname':''},"Mode":{'dbData':telemetryDictionary['St Mode'],'fname':''}}}},
    { id: 'Time', type: 'timebox',parentNode:'GNC', position:{x:1050, y:100}, data:{targetDate: '2024-12-26T23:00:00Z',value:{}}},
    { id: 'IRU', type: 'exxbox',parentNode:'GNC', position:{x:1050, y:400}, data:{name:'IRU',oriHeight:'150px',value:{"Pwr":{'dbData':telemetryDictionary['IRU Pwr'],'fname':''},"Gryo Est":{'dbData':telemetryDictionary['IRU Gryo Est Status'],'fname':''},"Sensor Good":{'dbData':telemetryDictionary['IRU Sensor Good'],'fname':''}}}},
    { id: 'FSSC', type: 'exxbox',parentNode:'GNC', position:{x:550, y:50}, data:{name:'Fine Sun Sensor 7-12', value:{"SS7 Int":{'dbData':telemetryDictionary['SS7 Intensity'],'fname':''},"SS8 Int":{'dbData':telemetryDictionary['SS8 Intensity'],'fname':''},"SS9 Int":{'dbData':telemetryDictionary['SS9 Intensity'],'fname':''},"SS10 Int":{'dbData':telemetryDictionary['SS10 Intensity'],'fname':''},"SS11 Int":{'dbData':telemetryDictionary['SS11 Intensity'],'fname':''},"SS12 Int":{'dbData':telemetryDictionary['SS12 Intensity'],'fname':''}}}},
    { id: 'FSSA', type: 'exxbox',parentNode:'GNC', position:{x:50, y:50}, data:{name:'Fine Sun Sensor 1-6', value:{"SS1 Int":{'dbData':telemetryDictionary['SS1 Intensity'],'fname':''},"SS2 Int":{'dbData':telemetryDictionary['SS2 Intensity'],'fname':''},"SS3 Int":{'dbData':telemetryDictionary['SS3 Intensity'],'fname':''},"SS4 Int":{'dbData':telemetryDictionary['SS4 Intensity'],'fname':''},"SS5 Int":{'dbData':telemetryDictionary['SS5 Intensity'],'fname':''},"SS6 Int":{'dbData':telemetryDictionary['SS6 Intensity'],'fname':''}}}},

    { id: 'Antennas', type: 'antennas',parentNode:'Comm', position:{x:2025, y:50}, data:{name:'', 'info':groundList, 'linfo':locationData}},
    { id: 'ScSketch', type: 'scSketch',parentNode:'Comm', position:{x:1050, y:275}, data:{name:'',select:telemetryDictionary['Ant State']}},
    { id: 'S-Band Transponder', type: 'exxbox',parentNode:'Comm', position:{x:575, y:275}, data:{name:'Radio', exWidth:'525px',value:{'TX Group Plug':{'dbData':telemetryDictionary['TX Installed'],'fname':'TX Group ENBL PLUG INSTALLED'},'Init Rout Status':{'dbData':telemetryDictionary['Init Rout Status'],'fname':''},'Init Rout Result':{'dbData':telemetryDictionary['Init Rout Result'],'fname':''},'Init Result':{'dbData':telemetryDictionary['Init Result'],'fname':''}}}},
    { id: 'Rx', type: 'exxbox',parentNode:'Comm', position:{x:50, y:525}, data:{name:'Rx',oriHeight:'150px', exWidth:'500px',value:{'Op Mode':{'dbData':telemetryDictionary['Rx Mode'],'fname':'Receiver Operating Mode','mname':'rxopmodest'},'Cmd Decrypt':{'dbData':telemetryDictionary['Command Decrypt'],'fname':'Command Decrypt'}}}},
    { id: 'Tx', type: 'exxbox',parentNode:'Comm', position:{x:50, y:275}, data:{name:'Tx',oriHeight:'150px', exWidth:'475px',value:{'Pwr':{'dbData':telemetryDictionary['Transmitter Pwr'],'fname':'Measurement of the transmitter power'},'Coherency En':{'dbData':telemetryDictionary['Coherency Enabled'],'fname':'Coherency Enabled'},'RF State':{'dbData':telemetryDictionary['RF Enable'],'fname':'Status of RF Enable command'}}}},
    { id: 'PassIndicaotr', type: 'passIndicator', parentNode: 'Comm', position: { x: 50, y: 50 }, data: { sigDet: telemetryDictionary['Signal Detected'], sigPwr: telemetryDictionary['Loop Signal power'], loopStress: telemetryDictionary['Loop Stress'], carrierLock:telemetryDictionary['Rx loop lock'], detLock:telemetryDictionary['Detector Lock'] , bitLock: telemetryDictionary['Bit-Sync Lock'], cmdDecode: telemetryDictionary['Cnd DS'] } },

    { id: 'Rad750', type: 'exxbox',parentNode:'CDH', position:{x:1075, y:125}, data:{name:'Rad750', exWidth:'550px', value:{"FSW Cmd Acpt Cnt":{'dbData':telemetryDictionary['FSW Cmd Acpt Cnt'],'fname':''},"CRC Acpt Cnt":{'dbData':telemetryDictionary['CRC Acpt Cnt'],'fname':''},"CRC Rj Cnt":{'dbData':telemetryDictionary['CRC Rj Cnt'],'fname':''},"Launch Mode Bit1":{'dbData':telemetryDictionary['Launch Mode Bit1'],'fname':''},"Launch Mode Bit2":{'dbData':telemetryDictionary['Launch Mode Bit2'],'fname':''},"PBK Critical VC":{'dbData':telemetryDictionary['PBK Critical VC'],'fname':''},"PT Critical VC":{'dbData':telemetryDictionary['PT Critical VC'],'fname':''}}}},
    { id: 'SMOAB', type: 'exxbox',parentNode:'CDH', position:{x:550, y:125}, data:{name:'Standard Multi-Operational Avionics(SMOAB)', exWidth:'500px', value:{"WD ISR":{'dbData':telemetryDictionary['SMOAB WatchDog ISR Task Status'],'fname':''}, "WD MBE":{'dbData':telemetryDictionary['SMOAB WatchDog MBE Task Status'],'fname':''}, "WD SBE":{'dbData':telemetryDictionary['SMOAB WatchDog SBE Task Status'],'fname':''}, "WD TLM":{'dbData':telemetryDictionary['SMOAB WatchDog TLM Task Status'],'fname':''}}}},
    { id: 'SSMB', type: 'exxbox',parentNode:'CDH', position:{x:50, y:125}, data:{name:'Solid State Memory Board(SSMB)', exWidth:'500px', value:{"MBE Err Cnt B1":{'dbData':telemetryDictionary['SSMB MBE Err Cnt B1'],'fname':''}, "MBE Err Cnt B2":{'dbData':telemetryDictionary['SSMB MBE Err Cnt B2'],'fname':''}, "MBE Err Cnt B3":{'dbData':telemetryDictionary['SSMB MBE Err Cnt B3'],'fname':''}, "SBE Err Cnt B1":{'dbData':telemetryDictionary['SSMB SBE Err Cnt B1'],'fname':''}, "SBE Err Cnt B2":{'dbData':telemetryDictionary['SSMB SBE Err Cnt B2'],'fname':''}, "SBE Err Cnt B3":{'dbData':telemetryDictionary['SSMB SBE Err Cnt B3'],'fname':''}}}},

    { id: 'Liveplot', type: 'liveplot',parentNode:'EPS', position:{x:50, y:450}, data:{name: 'Battery Voltage',mne:'pwmclasibusv', sc:source}},
    { id: 'Liveplot2', type: 'liveplot',parentNode:'EPS', position:{x:1400, y:450}, data:{name: 'Battery Current', mne:'pwbi', sc:source}},
    { id: 'Battery', type: 'exxbox',parentNode:'EPS', position:{x:1900, y:100}, data:{name:'Battery', oriHeight:'300px', exWidth:'600px',value:{"Curr":{'dbData':telemetryDictionary['Battery Curr'],'fname':''}, "Volt":{'dbData':telemetryDictionary['Battery Volt'],'fname':''}, "Temp":{'dbData':telemetryDictionary['Battery Temp'],'fname':''}, "Curr From SACI":{'dbData':telemetryDictionary['Battery Curr SACI'],'fname':''}, "Volt From SACI":{'dbData':telemetryDictionary['Battery Volt SACI'],'fname':''}}}},
    { id: 'BRB', type: 'exxbox',parentNode:'EPS', position:{x:1050, y:100}, data:{name:'Battery Relay Box (BRB)', oriHeight:'300px', exWidth:'575px', value:{'K1k2 Coil State':{'dbData':telemetryDictionary['Brb K1k2 Coil State'],'fname':''}, 'Rly1 Sep State':{'dbData':telemetryDictionary['Brb Sep Rly1 State'],'fname':''}, 'Rly2 Sep State':{'dbData':telemetryDictionary['Brb Sep Rly2 State'],'fname':''}, "Temp":{'dbData':telemetryDictionary['Brb Temp'],'fname':''}}}},
    { id: 'SolarArray', type: 'solarArray',parentNode:'EPS', position:{x:50, y:100}, data:{name:'Solar Array', oriHeight:'300px', exWidth:'500px', value:{"SA Curr":{'dbData':telemetryDictionary['SA Current'],'fname':''}, "SA1 Pwr":{'dbData':telemetryDictionary['SA1 Pwr'],'fname':''}, "SA2 Pwr":{'dbData':telemetryDictionary['SA2 Pwr'],'fname':''}, "SA3 Pwr":{'dbData':telemetryDictionary['SA3 Pwr'],'fname':''}, "SA4 Pwr":{'dbData':telemetryDictionary['SA4 Pwr'],'fname':''}, "SA5 Pwr":{'dbData':telemetryDictionary['SA5 Pwr'],'fname':''}, "SA6 Pwr":{'dbData':telemetryDictionary['SA6 Pwr'],'fname':''}, "SA7 Pwr":{'dbData':telemetryDictionary['SA7 Pwr'],'fname':''}, "SA8 Pwr":{'dbData':telemetryDictionary['SA8 Pwr'],'fname':''},"SA9 Pwr":{'dbData':telemetryDictionary['SA9 Pwr'],'fname':''}, "SA10 Pwr":{'dbData':telemetryDictionary['SA10 Pwr'],'fname':''}, "SA11 Pwr":{'dbData':telemetryDictionary['SA11 Pwr'],'fname':''}, "SA12 Pwr":{'dbData':telemetryDictionary['SA12 Pwr'],'fname':''}}}},

    { id: 'NFI HVPS', type: 'exxbox',parentNode:'Payload', position:{x:250, y:100}, data:{name:'NFI HVPS',oriHeight:'150px', exWidth:'500px', value:{"HV Pwr":{'dbData':telemetryDictionary['NFI HV'],'fname':''}, "HV Temp":{'dbData':telemetryDictionary['NFI HV Temp'],'fname':""}, "HV13 Vmon":{'dbData':telemetryDictionary['NFI HV Current'],'fname':''}}}},
    { id: 'NFI Cam', type: 'exxbox',parentNode:'Payload', position:{x:250, y:400}, data:{name:'NFI Cam',oriHeight:'150px', value:{"Cam Pwr":{'dbData':telemetryDictionary['NFI Cam Pwr'],'fname':''}, "Cam State":{'dbData':telemetryDictionary['NFI Image State'],'fname':''}, "Cam IMon":{'dbData':telemetryDictionary['NFI Cam IMon'],'fname':''}}}},
    { id: 'NFI Optics', type: 'exxbox',parentNode:'Payload', position:{x:250, y:700}, data:{name:'NFI Optics',oriHeight:'150px', value:{"Opt 1 Temp":{'dbData':telemetryDictionary['NFI Opt 1 Temp'],'fname':''}, "Opt 2 Temp":{'dbData':telemetryDictionary['NFI Opt 2 Temp'],'fname':''}}}},
    { id: 'NFI Filter', type: 'exxbox',parentNode:'Payload', position:{x:250, y:1000}, data:{name:'NFI Filter',oriHeight:'150px', value:{"Filter Pos":{'dbData':telemetryDictionary['NFI Filter Position'],'fname':''}, "Filter Temp":{'dbData':telemetryDictionary['NFI Fw Temp'],'fname':''}}}},
    { id: 'WFI HVPS', type: 'exxbox',parentNode:'Payload', position:{x:850, y:100}, data:{name:'WFI HVPS',oriHeight:'150px', value:{"HV Pwr":{'dbData':telemetryDictionary['WFI HV'],'fname':''}, "HV Temp":{'dbData':telemetryDictionary['WFI HV Temp'],'fname':""}, "HV13 Vmon":{'dbData':telemetryDictionary['WFI HV Current'],'fname':''}}}},
    { id: 'WFI Cam', type: 'exxbox',parentNode:'Payload', position:{x:850, y:400}, data:{name:'WFI Cam',oriHeight:'150px', value:{"Cam Pwr":{'dbData':telemetryDictionary['WFI Cam Pwr'],'fname':''}, "Cam State":{'dbData':telemetryDictionary['WFI Image State'],'fname':''}, "Cam IMon":{'dbData':telemetryDictionary['WFI Cam IMon'],'fname':''}}}},
    { id: 'WFI Optics', type: 'exxbox',parentNode:'Payload', position:{x:850, y:700}, data:{name:'WFI Optics',oriHeight:'150px', value:{"Opt 1 Temp":{'dbData':telemetryDictionary['WFI Opt 1 Temp'],'fname':''}, "Opt 2 Temp":{'dbData':telemetryDictionary['WFI Opt 2 Temp'],'fname':''}}}},
    { id: 'WFI Filter', type: 'exxbox',parentNode:'Payload', position:{x:850, y:1000}, data:{name:'WFI Filter',oriHeight:'150px', value:{"Filter Pos":{'dbData':telemetryDictionary['WFI Filter Position'],'fname':''}, "Filter Temp":{'dbData':telemetryDictionary['WFI Fw Temp'],'fname':''}}}},

    { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:150, y:-115}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

]);

  const createNodes = useCallback((telemetryDictionary: TelemetryDictionary) => {
    return [
      { id: 'Payload', type: 'panel', position:{x:150, y:50}, data:{'source':source,width:1600, height:1200,name:"Payload", hlink:"http://ops-display.ssl.berkeley.edu/d/c4d12ad6-f45b-44ac-8cd5-a755ea2c142a/escapade-payload-gui?orgId=1&refresh=5s&from=1721643945501&to=1721687145501"}},
      { id: 'CDH', type: 'panel', position:{x:150, y:1250}, data:{'source':source,width:1650, height:800,name:"CDH", hlink:"http://ops-display.ssl.berkeley.edu/d/edpzc6xclv4zkd/escapade-cdh?orgId=1&refresh=5s&from=1721644020030&to=1721687220030"}},
      { id: 'Comm', type: 'panel', position:{x:1800, y:1250}, data:{'source':source,width:2600, height:800,name:"Comm", hlink:"http://ops-display.ssl.berkeley.edu/d/adlq63qhlb9xca/escapade-comm?orgId=1&refresh=5s"}},
      { id: 'Prop', type: 'panel', position:{x:150, y:2050}, data:{'source':source,width:1200, height:650,name:"Prop", hlink:"http://ops-display.ssl.berkeley.edu/d/cdmiaiqo317nke/escapade-prop?orgId=1&refresh=5s"}},
      { id: 'EPS', type: 'panel', position:{x:1750, y:50}, data:{'source':source,width:2650, height:1200, name:"EPS", hlink:"http://ops-display.ssl.berkeley.edu/d/fdiy1s7uoj85cf/escapade-eps?orgId=1&refresh=5s&from=1721644177832&to=1721687377833"}},
      { id: 'GNC', type: 'panel', position:{x:1350, y:2050}, data:{'source':source,width:3050, height:650, name:"GNC", hlink:"http://ops-display.ssl.berkeley.edu/d/cdpvpsrck6k8wc/escapade-gnc?orgId=1&refresh=5s&from=1721644200155&to=1721687400155"}},
      { id: 'FDIR', type: 'panel', position:{x:150, y:2700}, data:{'source':source,width:4250, height:250, name:"TMon"}},
 
      { id: 'TMon OverVolt', type: 'exxFdirBox',parentNode:'FDIR', position:{x:3650, y:50}, data:{name:'Over Voltage',oriHeight:'150px', value:{"OV 1":{'dbData':telemetryDictionary['TMon 03 Triggered'],'fname':''}}}},
      { id: 'TMon UnderVolt', type: 'exxFdirBox',parentNode:'FDIR', position:{x:3050, y:50}, data:{name:'Under Voltage',oriHeight:'150px', value:{"UV 1":{'dbData':telemetryDictionary['TMon 05 Triggered'],'fname':''},"UV 2":{'dbData':telemetryDictionary['TMon 07 Triggered'],'fname':''},"UV 3":{'dbData':telemetryDictionary['TMon 08 Triggered'],'fname':''}}}}, 
      { id: 'TMon RW', type: 'exxFdirBox',parentNode:'FDIR', position:{x:2450, y:50}, data:{name:'Reaction Wheel',oriHeight:'150px', value:{"RW1 SL3":{'dbData':telemetryDictionary['TMon 28 Triggered'],'fname':''}, "RW2 SL3":{'dbData':telemetryDictionary['TMon 29 Triggered'],'fname':''}, "RW3 SL3":{'dbData':telemetryDictionary['TMon 30 Triggered'],'fname':''}, "RW4 SL3":{'dbData':telemetryDictionary['TMon 31 Triggered'],'fname':''}, "RW1 SL4":{'dbData':telemetryDictionary['TMon 32 Triggered'],'fname':''}, "RW2 SL4":{'dbData':telemetryDictionary['TMon 33 Triggered'],'fname':''}, "RW3 SL4":{'dbData':telemetryDictionary['TMon 34 Triggered'],'fname':''}, "RW4 SL4":{'dbData':telemetryDictionary['TMon 35 Triggered'],'fname':''}}}},
      { id: 'TMon Payload', type: 'exxFdirBox',parentNode:'FDIR', position:{x:1850, y:50}, data:{name:'Payload',oriHeight:'150px', value:{"Comm Err":{'dbData':telemetryDictionary['TMon 170 Triggered'],'fname':''},"Shutdown Req":{'dbData':telemetryDictionary['TMon 171 Triggered'],'fname':''},"OC":{'dbData':telemetryDictionary['TMon 172 Triggered'],'fname':'Payload Over Current'},"FW OC":{'dbData':telemetryDictionary['TMon 173 Triggered'],'fname':'Filter Wheel Over Current'}}}},
      { id: 'TMon IRU', type: 'exxFdirBox',parentNode:'FDIR', position:{x:1250, y:50}, data:{name:'IRU',oriHeight:'150px', value:{"SEE-SEU":{'dbData':telemetryDictionary['TMon 70 Triggered'],'fname':''},"OT/ME":{'dbData':telemetryDictionary['TMon 71 Triggered'],'fname':'Over Temperature Or Memory Error'}, "Locked Up":{'dbData':telemetryDictionary['TMon 72 Triggered'],'fname':'Tracker Locked Up'}}}},
      { id: 'TMon Star Tracker', type: 'exxFdirBox',parentNode:'FDIR', position:{x:650, y:50}, data:{name:'Star Tracker',oriHeight:'150px', value:{"Out of Solution":{'dbData':telemetryDictionary['TMon 64 Triggered'],'fname':''},"Locked Up":{'dbData':telemetryDictionary['TMon 65 Triggered'],'fname':''}, "Loss of Sync":{'dbData':telemetryDictionary['TMon 66 Triggered'],'fname':''}}}},
      { id: 'TMon ADCS', type: 'exxFdirBox',parentNode:'FDIR', position:{x:50, y:50}, data:{name:'ADCS',oriHeight:'150px', value:{"High Mom 1":{'dbData':telemetryDictionary['TMon 43 Triggered'],'fname':''}, "Att/Rate Err 1":{'dbData':telemetryDictionary['TMon 53 Triggered'],'fname':''},"Eph Err/Invalid Att":{'dbData':telemetryDictionary['TMon 56 Triggered'],'fname':''}}}},

      { id: 'PropThruster1', type: 'propThruster',parentNode:'Prop', position:{x:300, y:400}, data:{value:{'F1':{'dbData':telemetryDictionary['Thruster T1'],'fname':''},'F2':{'dbData':telemetryDictionary['Thruster T2'],'fname':''},'F3':{'dbData':telemetryDictionary['Thruster T3'],'fname':''},'F4':{'dbData':telemetryDictionary['Thruster T4'],'fname':''}}}},
      { id: 'PropTank', type: 'exxCir',parentNode:'Prop', position:{x:500, y:100}, data:{name:'GN2/N2H4', value:{'GN2 Temp':{'dbData':telemetryDictionary['Prop GN2 Temp'],'fname':''},'N2H4 Temp':{'dbData':telemetryDictionary['Prop N2H4 Temp'],'fname':''},}}},

      { id: 'RW4', type: 'exxbox',parentNode:'GNC', position:{x:2550, y:300}, data:{name:'Reaction Wheel 4',oriHeight:'150px', value:{'Power':{'dbData':telemetryDictionary['RW1 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW1 Speed/dir'],'fname':''}}}},
      { id: 'RW3', type: 'exxbox',parentNode:'GNC', position:{x:2550, y:50}, data:{name:'Reaction Wheel 3',oriHeight:'150px', value:{'Power':{'dbData':telemetryDictionary['RW2 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW2 Speed/dir'],'fname':''}}}},
      { id: 'RW2', type: 'exxbox',parentNode:'GNC', position:{x:2050, y:300}, data:{name:'Reaction Wheel 2',oriHeight:'150px', value:{'Power':{'dbData':telemetryDictionary['RW3 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW3 Speed/dir'],'fname':''}}}},
      { id: 'RW1', type: 'exxbox',parentNode:'GNC', position:{x:2050, y:50}, data:{name:'Reaction Wheel 1',oriHeight:'150px', value:{'Power':{'dbData':telemetryDictionary['RW4 Pwr'],'fname':''}, 'Speed/Dir':{'dbData':telemetryDictionary['RW4 Speed/dir'],'fname':''}}}},
      { id: 'St', type: 'exxbox',parentNode:'GNC', position:{x:1550, y:400}, data:{name:'Star Tracker',oriHeight:'150px', value:{"Pwr":{'dbData':telemetryDictionary['St Pwr'],'fname':''},"Mode":{'dbData':telemetryDictionary['St Mode'],'fname':''}}}},
      { id: 'Time', type: 'timebox',parentNode:'GNC', position:{x:1050, y:100}, data:{targetDate: '2024-12-26T23:00:00Z',value:{}}},
      { id: 'IRU', type: 'exxbox',parentNode:'GNC', position:{x:1050, y:400}, data:{name:'IRU',oriHeight:'150px',value:{"Pwr":{'dbData':telemetryDictionary['IRU Pwr'],'fname':''},"Gryo Est":{'dbData':telemetryDictionary['IRU Gryo Est Status'],'fname':''},"Sensor Good":{'dbData':telemetryDictionary['IRU Sensor Good'],'fname':''}}}},
      { id: 'FSSC', type: 'exxbox',parentNode:'GNC', position:{x:550, y:50}, data:{name:'Fine Sun Sensor 7-12', value:{"SS7 Int":{'dbData':telemetryDictionary['SS7 Intensity'],'fname':''},"SS8 Int":{'dbData':telemetryDictionary['SS8 Intensity'],'fname':''},"SS9 Int":{'dbData':telemetryDictionary['SS9 Intensity'],'fname':''},"SS10 Int":{'dbData':telemetryDictionary['SS10 Intensity'],'fname':''},"SS11 Int":{'dbData':telemetryDictionary['SS11 Intensity'],'fname':''},"SS12 Int":{'dbData':telemetryDictionary['SS12 Intensity'],'fname':''}}}},
      { id: 'FSSA', type: 'exxbox',parentNode:'GNC', position:{x:50, y:50}, data:{name:'Fine Sun Sensor 1-6', value:{"SS1 Int":{'dbData':telemetryDictionary['SS1 Intensity'],'fname':''},"SS2 Int":{'dbData':telemetryDictionary['SS2 Intensity'],'fname':''},"SS3 Int":{'dbData':telemetryDictionary['SS3 Intensity'],'fname':''},"SS4 Int":{'dbData':telemetryDictionary['SS4 Intensity'],'fname':''},"SS5 Int":{'dbData':telemetryDictionary['SS5 Intensity'],'fname':''},"SS6 Int":{'dbData':telemetryDictionary['SS6 Intensity'],'fname':''}}}},

      { id: 'Antennas', type: 'antennas',parentNode:'Comm', position:{x:2025, y:50}, data:{name:'', 'info':groundList, 'linfo':locationData}},
      { id: 'ScSketch', type: 'scSketch',parentNode:'Comm', position:{x:1050, y:275}, data:{name:'',select:telemetryDictionary['Ant State']}},
      { id: 'S-Band Transponder', type: 'exxbox',parentNode:'Comm', position:{x:575, y:275}, data:{name:'Radio', exWidth:'525px',value:{'TX Group Plug':{'dbData':telemetryDictionary['TX Installed'],'fname':'TX Group ENBL PLUG INSTALLED'},'Init Rout Status':{'dbData':telemetryDictionary['Init Rout Status'],'fname':''},'Init Rout Result':{'dbData':telemetryDictionary['Init Rout Result'],'fname':''},'Init Result':{'dbData':telemetryDictionary['Init Result'],'fname':''}}}},
      { id: 'Rx', type: 'exxbox',parentNode:'Comm', position:{x:50, y:525}, data:{name:'Rx',oriHeight:'150px', exWidth:'500px',value:{'Op Mode':{'dbData':telemetryDictionary['Rx Mode'],'fname':'Receiver Operating Mode','mname':'rxopmodest'},'Cmd Decrypt':{'dbData':telemetryDictionary['Command Decrypt'],'fname':'Command Decrypt'}}}},
      { id: 'Tx', type: 'exxbox',parentNode:'Comm', position:{x:50, y:275}, data:{name:'Tx',oriHeight:'150px', exWidth:'475px',value:{'Pwr':{'dbData':telemetryDictionary['Transmitter Pwr'],'fname':'Measurement of the transmitter power'},'Coherency En':{'dbData':telemetryDictionary['Coherency Enabled'],'fname':'Coherency Enabled'},'RF State':{'dbData':telemetryDictionary['RF Enable'],'fname':'Status of RF Enable command'}}}},
      { id: 'PassIndicaotr', type: 'passIndicator', parentNode: 'Comm', position: { x: 50, y: 50 }, data: { sigDet: telemetryDictionary['Signal Detected'], sigPwr: telemetryDictionary['Loop Signal power'], loopStress: telemetryDictionary['Loop Stress'], carrierLock:telemetryDictionary['Rx loop lock'], detLock:telemetryDictionary['Detector Lock'] , bitLock: telemetryDictionary['Bit-Sync Lock'], cmdDecode: telemetryDictionary['Cnd DS'] } },

      { id: 'Rad750', type: 'exxbox',parentNode:'CDH', position:{x:1075, y:125}, data:{name:'Rad750', exWidth:'550px', value:{"FSW Cmd Acpt Cnt":{'dbData':telemetryDictionary['FSW Cmd Acpt Cnt'],'fname':''},"CRC Acpt Cnt":{'dbData':telemetryDictionary['CRC Acpt Cnt'],'fname':''},"CRC Rj Cnt":{'dbData':telemetryDictionary['CRC Rj Cnt'],'fname':''},"Launch Mode Bit1":{'dbData':telemetryDictionary['Launch Mode Bit1'],'fname':''},"Launch Mode Bit2":{'dbData':telemetryDictionary['Launch Mode Bit2'],'fname':''},"PBK Critical VC":{'dbData':telemetryDictionary['PBK Critical VC'],'fname':''},"PT Critical VC":{'dbData':telemetryDictionary['PT Critical VC'],'fname':''}}}},
      { id: 'SMOAB', type: 'exxbox',parentNode:'CDH', position:{x:550, y:125}, data:{name:'Standard Multi-Operational Avionics(SMOAB)', exWidth:'500px', value:{"WD ISR":{'dbData':telemetryDictionary['SMOAB WatchDog ISR Task Status'],'fname':''}, "WD MBE":{'dbData':telemetryDictionary['SMOAB WatchDog MBE Task Status'],'fname':''}, "WD SBE":{'dbData':telemetryDictionary['SMOAB WatchDog SBE Task Status'],'fname':''}, "WD TLM":{'dbData':telemetryDictionary['SMOAB WatchDog TLM Task Status'],'fname':''}}}},
      { id: 'SSMB', type: 'exxbox',parentNode:'CDH', position:{x:50, y:125}, data:{name:'Solid State Memory Board(SSMB)', exWidth:'500px', value:{"MBE Err Cnt B1":{'dbData':telemetryDictionary['SSMB MBE Err Cnt B1'],'fname':''}, "MBE Err Cnt B2":{'dbData':telemetryDictionary['SSMB MBE Err Cnt B2'],'fname':''}, "MBE Err Cnt B3":{'dbData':telemetryDictionary['SSMB MBE Err Cnt B3'],'fname':''}, "SBE Err Cnt B1":{'dbData':telemetryDictionary['SSMB SBE Err Cnt B1'],'fname':''}, "SBE Err Cnt B2":{'dbData':telemetryDictionary['SSMB SBE Err Cnt B2'],'fname':''}, "SBE Err Cnt B3":{'dbData':telemetryDictionary['SSMB SBE Err Cnt B3'],'fname':''}}}},

      { id: 'Liveplot', type: 'liveplot',parentNode:'EPS', position:{x:50, y:450}, data:{name: 'Battery Voltage',mne:'pwmclasibusv', sc:source}},
      { id: 'Liveplot2', type: 'liveplot',parentNode:'EPS', position:{x:1400, y:450}, data:{name: 'Battery Current', mne:'pwbi', sc:source}},
      { id: 'Battery', type: 'exxbox',parentNode:'EPS', position:{x:1900, y:100}, data:{name:'Battery', oriHeight:'300px', exWidth:'600px',value:{"Curr":{'dbData':telemetryDictionary['Battery Curr'],'fname':''}, "Volt":{'dbData':telemetryDictionary['Battery Volt'],'fname':''}, "Temp":{'dbData':telemetryDictionary['Battery Temp'],'fname':''}, "Curr From SACI":{'dbData':telemetryDictionary['Battery Curr SACI'],'fname':''}, "Volt From SACI":{'dbData':telemetryDictionary['Battery Volt SACI'],'fname':''}}}},
      { id: 'BRB', type: 'exxbox',parentNode:'EPS', position:{x:1050, y:100}, data:{name:'Battery Relay Box (BRB)', oriHeight:'300px', exWidth:'575px', value:{'K1k2 Coil State':{'dbData':telemetryDictionary['Brb K1k2 Coil State'],'fname':''}, 'Rly1 Sep State':{'dbData':telemetryDictionary['Brb Sep Rly1 State'],'fname':''}, 'Rly2 Sep State':{'dbData':telemetryDictionary['Brb Sep Rly2 State'],'fname':''}, "Temp":{'dbData':telemetryDictionary['Brb Temp'],'fname':''}}}},
      { id: 'SolarArray', type: 'solarArray',parentNode:'EPS', position:{x:50, y:100}, data:{name:'Solar Array', oriHeight:'300px', exWidth:'500px', value:{"SA Curr":{'dbData':telemetryDictionary['SA Current'],'fname':''}, "SA1 Pwr":{'dbData':telemetryDictionary['SA1 Pwr'],'fname':''}, "SA2 Pwr":{'dbData':telemetryDictionary['SA2 Pwr'],'fname':''}, "SA3 Pwr":{'dbData':telemetryDictionary['SA3 Pwr'],'fname':''}, "SA4 Pwr":{'dbData':telemetryDictionary['SA4 Pwr'],'fname':''}, "SA5 Pwr":{'dbData':telemetryDictionary['SA5 Pwr'],'fname':''}, "SA6 Pwr":{'dbData':telemetryDictionary['SA6 Pwr'],'fname':''}, "SA7 Pwr":{'dbData':telemetryDictionary['SA7 Pwr'],'fname':''}, "SA8 Pwr":{'dbData':telemetryDictionary['SA8 Pwr'],'fname':''},"SA9 Pwr":{'dbData':telemetryDictionary['SA9 Pwr'],'fname':''}, "SA10 Pwr":{'dbData':telemetryDictionary['SA10 Pwr'],'fname':''}, "SA11 Pwr":{'dbData':telemetryDictionary['SA11 Pwr'],'fname':''}, "SA12 Pwr":{'dbData':telemetryDictionary['SA12 Pwr'],'fname':''}}}},

      { id: 'NFI HVPS', type: 'exxbox',parentNode:'Payload', position:{x:250, y:100}, data:{name:'NFI HVPS',oriHeight:'150px', exWidth:'500px', value:{"HV Pwr":{'dbData':telemetryDictionary['NFI HV'],'fname':''}, "HV Temp":{'dbData':telemetryDictionary['NFI HV Temp'],'fname':""}, "HV13 Vmon":{'dbData':telemetryDictionary['NFI HV Current'],'fname':''}}}},
      { id: 'NFI Cam', type: 'exxbox',parentNode:'Payload', position:{x:250, y:400}, data:{name:'NFI Cam',oriHeight:'150px', value:{"Cam Pwr":{'dbData':telemetryDictionary['NFI Cam Pwr'],'fname':''}, "Cam State":{'dbData':telemetryDictionary['NFI Image State'],'fname':''}, "Cam IMon":{'dbData':telemetryDictionary['NFI Cam IMon'],'fname':''}}}},
      { id: 'NFI Optics', type: 'exxbox',parentNode:'Payload', position:{x:250, y:700}, data:{name:'NFI Optics',oriHeight:'150px', value:{"Opt 1 Temp":{'dbData':telemetryDictionary['NFI Opt 1 Temp'],'fname':''}, "Opt 2 Temp":{'dbData':telemetryDictionary['NFI Opt 2 Temp'],'fname':''}}}},
      { id: 'NFI Filter', type: 'exxbox',parentNode:'Payload', position:{x:250, y:1000}, data:{name:'NFI Filter',oriHeight:'150px', value:{"Filter Pos":{'dbData':telemetryDictionary['NFI Filter Position'],'fname':''}, "Filter Temp":{'dbData':telemetryDictionary['NFI Fw Temp'],'fname':''}}}},
      { id: 'WFI HVPS', type: 'exxbox',parentNode:'Payload', position:{x:850, y:100}, data:{name:'WFI HVPS',oriHeight:'150px', value:{"HV Pwr":{'dbData':telemetryDictionary['WFI HV'],'fname':''}, "HV Temp":{'dbData':telemetryDictionary['WFI HV Temp'],'fname':""}, "HV13 Vmon":{'dbData':telemetryDictionary['WFI HV Current'],'fname':''}}}},
      { id: 'WFI Cam', type: 'exxbox',parentNode:'Payload', position:{x:850, y:400}, data:{name:'WFI Cam',oriHeight:'150px', value:{"Cam Pwr":{'dbData':telemetryDictionary['WFI Cam Pwr'],'fname':''}, "Cam State":{'dbData':telemetryDictionary['WFI Image State'],'fname':''}, "Cam IMon":{'dbData':telemetryDictionary['WFI Cam IMon'],'fname':''}}}},
      { id: 'WFI Optics', type: 'exxbox',parentNode:'Payload', position:{x:850, y:700}, data:{name:'WFI Optics',oriHeight:'150px', value:{"Opt 1 Temp":{'dbData':telemetryDictionary['WFI Opt 1 Temp'],'fname':''}, "Opt 2 Temp":{'dbData':telemetryDictionary['WFI Opt 2 Temp'],'fname':''}}}},
      { id: 'WFI Filter', type: 'exxbox',parentNode:'Payload', position:{x:850, y:1000}, data:{name:'WFI Filter',oriHeight:'150px', value:{"Filter Pos":{'dbData':telemetryDictionary['WFI Filter Position'],'fname':''}, "Filter Temp":{'dbData':telemetryDictionary['WFI Fw Temp'],'fname':''}}}},

      { id: 'InfoBox', type: 'vmodeBox',parentNode:'', position:{x:150, y:-115}, data:{name:'', VMode:telemetryDictionary['Vehicle Mode Agg'], playBackValue: {time: pbTimeRef.current, loop: pbLoopRef.current}}},

    ]}, [source]); 

    // Effect to check if data is ready
  useEffect(() => {
    if (influxData && Object.keys(influxData).length > 0) {
      setIsDataReady(true);
    }
  }, [influxData]);

    useEffect(() => {
      if (!isDataReady || !guiValues.current.pbisInPlayBack) {
        return;
      }
    
      let isCancelled = false;
    
      const processData = async () => {
        // Initialize an empty object to store telemetry data
        let aggregatedData: TelemetryDictionary = {};
        const totalLoops = Object.keys(influxData).length;
    
        for (let i = 0; i < totalLoops; i++) {
          // Check if the process should stop
          if (!guiValues.current.pbisInPlayBack || isCancelled) {
            break;
          }

          const key = Object.keys(influxData)[i];
          const selectedData = influxData[key];
            pbTimeRef.current = key;
            const newValue = (i + 1)/totalLoops;
            pbLoopRef.current = newValue;
    
          // Loop through each item in selectedData and update the aggregatedData object
          selectedData.forEach(item => {
            // If the key already exists, update the telemetry value
            if (aggregatedData[item.name]) {
              if (item.cnvValue !== '') {
                aggregatedData[item.name].telemetry = item.cnvValue;
              }
            } else {
              aggregatedData[item.name] = {
                telemetry: item.cnvValue,
                mne: item.mne,
                limit: "",
                unit: "",
                spacecraft: "",
                live: "rgb(68, 169, 241)",
              };
            } 
          });

          // Create nodes from the aggregatedData object
          const newNodes = createNodes(aggregatedData);
          setNodes(newNodes);
    
          // Log the current loop progress
          console.log(`${i + 1}/${totalLoops}`);
    
          // Wait for 2 seconds before processing the next item
          await new Promise(resolve => setTimeout(resolve, 2000));

        }
        console.log('Playback completed');
        guiValues.current.pbstopPlay()
      };
    
      processData();
    
      // Cleanup function to cancel the loop if pbisInPlayBack changes
      return () => {
        isCancelled = true;
      };
    }, [influxData, isDataReady, guiValues, createNodes]);

    useEffect(() => {
      //console.log('Playback started asfafa', guiValues.pbisInPlayBack);
      if (guiValues.current.pbisInPlayBack) {
        return;
      }
      const telemetryDictionary1 = createTelemetryDictionary();
    
      const newNodes = createNodes(telemetryDictionary1); // Function to create nodes from telemetryDictionary
      setNodes(newNodes);
    }, [createNodes, createTelemetryDictionary, guiValues]);

  enum MarkerType {
    Arrow = 'arrow',
    ArrowClosed = 'arrowclosed',
  }

  const arrow = {type: MarkerType.ArrowClosed, color: 'white', orient: 'auto-start-reverse', width: 15, height: 15};
  
  const initialEdges = [
    {id: 'edge1', source: 'SolarArrsadfaay',sourceHandle: 'source-left', target: 'BCR',targetHandle:'target-right', type:'customEdge',style: { stroke: 'white', strokeWidth:5}, markerEnd: arrow},

 ]; 

  const proOptions = { hideAttribution: true };

  return (
    <ReactFlowProvider>
      <div className={reactAppWrapper} style={{ height: height, width: width }}>
          <ReactFlow          
          edges={initialEdges}
          onInit={onInit}
          nodes={nodes}
          nodeTypes={nodeTypes}
          proOptions={proOptions}
          //defaultViewport={{ x: option.X, y: option.Y, zoom: option.Zoom}}
          minZoom={0.1}
          panOnDrag={true}
          zoomOnScroll={true}
          zoomOnPinch={true} 
          zoomOnDoubleClick={true}
          fitView={true}
          />
        </div>
  
    </ReactFlowProvider>
  );

}

export default App;
