import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { InnerData, checkOutOfLimitsEx, checkOutOfLimitsHid, determineColorNew, handleCopyMneName } from './utils';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme';
import { css, cx } from '@emotion/css';

interface SolarProp {
  data: {
    name: string;
    value: InnerData;
    exWidth: string;
    oriHeight: string;
  };
}

type VisibleTooltips = { [key: string]: boolean };

const SolarArray: React.FC<SolarProp> = ({ data }) => {
  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});
  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});

  const handleMneNameClick = (key: string) => {
    setVisibleTooltips((prevVisibleTooltips) => ({
      ...prevVisibleTooltips,
      [key]: true,
    }));

    setTimeout(() => {
      setVisibleTooltips((prevVisibleTooltips) => ({
        ...prevVisibleTooltips,
        [key]: false,
      }));
    }, 5000);
  };

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops((prevVisibleLimitPops) => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key],
    }));
  };

  const [isContentVisible, setIsContentVisible] = useState(false); // State to toggle content visibility

  const handleParentClick = () => {
    if (!isContentVisible) {
      setIsContentVisible(true);
    }
  };

    // Define styles for the div
    const defaultStyle = css`
    background-color: ${checkOutOfLimitsHid(data.value)}; // Default background color
    width: 450px;
    height: ${data.oriHeight ? data.oriHeight : '450px'};
    display: flex; 
    justify-content: center;  
    align-items: center;
    cursor: pointer; // To indicate the div is clickable
    transition: all 0.3s ease-in-out; // Add transition for smooth effect

  `;

  const clickedStyle = css`
    //background-color: ${checkOutOfLimitsEx(data.value)}; // Background color when clicked
    background-color: black;
    width: ${data.exWidth ? data.exWidth : '450px'};
    cursor: pointer; // To indicate the div is clickable
    transition: all 0.3s ease-in-out; // Add transition for smooth effect
    //border: ${checkOutOfLimitsEx(data.value) === 'black' ? '5px solid rgb(104, 176, 253)' : 'none'};
    border: 5px solid rgb(104, 176, 253);
  `;

  const handleCollapseClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering the parent click
    setIsContentVisible(false);
  };

  return (
    <div className={cx(isContentVisible ? clickedStyle : defaultStyle)} onClick={handleParentClick}>
      <Handle type="target" position={Position.Top} id="target-top" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Left} id="target-left" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Right} id="target-right" style={{ opacity: 0 }} />
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Top} id="source-top" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Left} id="source-left" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Right} id="source-right" style={{ opacity: 0 }} />
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{ opacity: 0 }} />

        <p onClick={handleCollapseClick}>
          {data.name} {isContentVisible && '«'}
        </p>

        {isContentVisible && ( <div
          className={css`
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            justify-content: space-evenly;
          `}
        >
          {/* First element - Full width  */}
          {data.value && Object.entries(data.value).map(([key, innerData], index) => (
            <div
              key={key}
              className={css`
                ${index === 0 ? 'flex: 1 0 100%;' : ''}
              `}
            >
              {index === 0 ? (
                <div className={scheme.tbValueContainer} key={key} >
                <div key={key} className={scheme.FnameKeyContainer} onClick={() => handleMneNameClick(key)}>
                  {key}:
                  {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}
                  <span data-comp="fname" className={scheme.tbFnameTooltip}>{innerData.fname}</span>
                </div>
                <div className={scheme.tbValueSpanContainer} onClick={() => handleValueChartClick(key)}>
                  <span 
                    className={cx(
                      scheme.value, 
                      css`color: ${innerData.dbData?.live}`,
                      scheme.classMap[determineColorNew(innerData.dbData?.limit)], 
                    )}
                  >
                    {innerData.dbData?.telemetry}
                  </span>
                  {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
                </div>
                {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart>
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                      Close Plot
                    </button>
                  </div>}
              </div>
              ) : (
                // Square box SVG for subsequent elements
                <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="-0.5 -0.5 100 100">
                  <rect
                    x="5"
                    y="5"
                    width="90"
                    height="90"
                    fill={innerData.dbData?.telemetry === 'ON' ? 'green' : 'gray'}
                    stroke="white"
                    strokeWidth="6"
                  />
                  <text x="50" y="55" textAnchor="middle" fill="white" fontSize="25">
                    {key.split(' ')[0]}
                  </text>
                </svg>
              )}
            </div>
          ))}
        </div> )}
    </div>
  );
};

export default SolarArray;
