import React, { useState } from 'react'
import {InnerData, checkOutOfLimitsHid, determineColorNew} from './utils';
import { Handle, Position } from 'reactflow';
import PlotlyChart from './PlotlyChart';
import * as scheme from './Scheme'; // Import your styles
import {css, cx} from '@emotion/css';
import copy from 'copy-to-clipboard';

interface PropThrusterProp{
  data: {
    name: string;
    value: InnerData;
    className: string;
  }
}

type VisibleTooltips = { [key: string]: boolean };

const PropThruster: React.FC<PropThrusterProp> = ({data}) => {

  const [visibleTooltips, setVisibleTooltips] = useState<VisibleTooltips>({});
  const [visibleLimitPops, setVisibleLimitPops] = useState<VisibleTooltips>({});
  const [isContentVisible, setIsContentVisible] = useState(false); // State to toggle content visibility

  const handleCopyMneName = (mne: string) => {
    copy(mne);
  };
  
  const handleMneNameClick = (key: string) => {
    setVisibleTooltips(prevVisibleTooltips => ({
      ...prevVisibleTooltips,
      [key]: true
    }));
  
    setTimeout(() => {
      setVisibleTooltips(prevVisibleTooltips => ({
        ...prevVisibleTooltips,
        [key]: false
      }));
    }, 5000);
  };

  const handleValueChartClick = (key: string) => {
    setVisibleLimitPops(prevVisibleLimitPops => ({
      ...prevVisibleLimitPops,
      [key]: !prevVisibleLimitPops[key]
    }));
  };

  const handleParentClick = () => {
      setIsContentVisible(!isContentVisible);
  };

  const propStyle = css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10px;
    width: 600px;
    background-color: #1E1E1E;

  `

  const thhursterColor = checkOutOfLimitsHid(data.value);

  return (
    <div className={css`width:600px;`}>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{opacity:0, top:30, left:550}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right-1" style={{opacity:0, top:30, right:550}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>


        <div onClick={handleParentClick}>

        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="121px" height="151px" viewBox="-0.5 -0.5 121 151">
          <defs/>
          <g>
            <g>
              <path d="M 0 150 L 20 20 L 100 20 L 120 150 Z" fill="none" stroke={thhursterColor} strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
            </g>
            <g>
              <rect x="20" y="0" width="80" height="20" fill="none" stroke={thhursterColor} strokeWidth="6" pointerEvents="all"/>
            </g>
          </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="121px" height="151px" viewBox="-0.5 -0.5 121 151">
          <defs/>
          <g>
            <g>
              <path d="M 0 150 L 20 20 L 100 20 L 120 150 Z" fill="none" stroke={thhursterColor} strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
            </g>
            <g>
              <rect x="20" y="0" width="80" height="20" fill="none" stroke={thhursterColor} strokeWidth="6" pointerEvents="all"/>
            </g>
          </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="121px" height="151px" viewBox="-0.5 -0.5 121 151">
          <defs/>
          <g>
            <g>
              <path d="M 0 150 L 20 20 L 100 20 L 120 150 Z" fill="none" stroke={thhursterColor} strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
            </g>
            <g>
              <rect x="20" y="0" width="80" height="20" fill="none" stroke={thhursterColor} strokeWidth="6" pointerEvents="all"/>
            </g>
          </g>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="121px" height="151px" viewBox="-0.5 -0.5 121 151">
          <defs/>
          <g>
            <g>
              <path d="M 0 150 L 20 20 L 100 20 L 120 150 Z" fill="none" stroke={thhursterColor} strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
            </g>
            <g>
              <rect x="20" y="0" width="80" height="20" fill="none" stroke={thhursterColor} strokeWidth="6" pointerEvents="all"/>
            </g>
          </g>
        </svg>
        </div>

        {isContentVisible && (
        <div className={cx(scheme.svgValueContainer, propStyle)}>
      {data.value && Object.entries(data.value).map(([key, innerData]) => (
            <div className={scheme.svgValueSpanContainer} key={key}>
            <span className={cx(scheme.svgKey, scheme.FnameKeyContainer)}onClick={() => handleMneNameClick(key)}>
                {key}
                {visibleTooltips[key] && <span className={scheme.mnameTooltip} onClick={() => handleCopyMneName(innerData.dbData.mne)}>{innerData.dbData.mne}</span>}

                <span data-comp="fname" className={scheme.svgFnameTooltip}>{innerData.fname}</span>
              </span>
              <span 
                onClick={() => handleValueChartClick(key)} 
                className={cx(
                  scheme.svgValue, 
                  css`color: ${innerData.dbData?.live}`,
                  scheme.classMap[determineColorNew(innerData.dbData?.limit)],
                )}
              >
                {innerData.dbData?.telemetry}
              </span>
              {innerData.dbData && innerData.dbData.unit && <span className={scheme.svgUnit}>{innerData.dbData.unit}</span>}
              {visibleLimitPops[key] && 
                  <div className={scheme.chartContainer}>
                    <PlotlyChart data={{ mne: innerData.dbData.mne, sc: innerData.dbData.spacecraft }}></PlotlyChart> 
                    <button className={scheme.plotButton} onClick={() => handleValueChartClick(key)}>
                        Close Plot
                    </button>
                  </div>}
            </div>
        ))}
        
        </div>      )}
    </div>
  );
}

export default PropThruster;
