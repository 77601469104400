import React, { useEffect, useRef, useState } from 'react';
import { DataSourceApi, PanelProps } from '@grafana/data';
import {SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { PanelDataErrorView, getDataSourceSrv, getTemplateSrv } from '@grafana/runtime';
import { TimeRangeContext } from './TimeRangeContext';
import * as dat from 'lil-gui';
import { LocationData, DataObject, ProcessedData, lookUpName, nameToMne, Field } from './utils';
import AppNew from './AppNew';
import moment from 'moment-timezone';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      fontFamily: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

interface ResultField {
  name: string;
  values: number[];
}

interface ResultItem {
  fields: ResultField[];
}

export const SimplePanel: React.FC<Props> = ({options, data, width, height, fieldConfig, id }) => {

  const styles = useStyles2(getStyles);
  const [groundData, setGroundData] = useState<Field[]>([]);
  const [scData, setScData] = useState<LocationData>({ Azimuth: 0, Elevation: 0 });

  const exampleFields: Field[] = [
    { name: "", type: "", values: []}
  ];

  const selectedData = data.series[0] ? data.series[0].fields : exampleFields;

  const variableName = 'ScName'; // Replace with your Grafana variable
  const source = getTemplateSrv().replace(`\${${variableName}}`);

  const fetchDataSch = async (query: string, dataSourceName: string): Promise<Field[]> => {
    try {
      const dataSource = await getDataSourceSrv().get(dataSourceName);
      //@ts-ignore
      const result = await dataSource.query({targets: [{ refId: 'queryB', rawSql: query, format: 'table' }]}).toPromise();
      return result.data.length > 0 ? result.data[0].fields : [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const fetchInfluxDB = async (query: string, dataSourceName: string) => {
    try {
      const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);
      //@ts-ignore
      const result = await dataSource.query({ targets: [{ query, refId: 'comQ' }] }).toPromise();
      return result.data;
    } catch (error) {
      console.error('Error fetching InfluxDB data:', error);
      return [];
    }
  };

  useEffect(() => {
    const exQuery = `SELECT station, scName, tAos, tLos, trCode 
      FROM bdps.operational_schedule 
      WHERE scName LIKE '${source}' AND tLos > UNIX_TIMESTAMP()
      ORDER BY tLos ASC 
      LIMIT 1;`;

    const influxQuery1 = `from(bucket: "fd_30_days")
    |> range(start: -15d)
    |> filter(fn: (r) => r._measurement == "ESCAPADE")
    |> filter(fn: (r) => r.Spacecraft == "${source}")
    |> filter(fn: (r) => r.Station == "DSS 34")
    |> last()`;

    fetchDataSch(exQuery, 'MySQL - BTAPS - CVT').then(result => {
      setGroundData(result);
    });

    fetchInfluxDB(influxQuery1, 'INFLUX').then(result => {

      //console.log('resulasfast', result);
      const azColumn = result.filter((litem: ResultItem) => litem.fields[1]?.name === "Azimuth [deg]");
      const elColumn = result.filter((litem: ResultItem) => litem.fields[1]?.name === "Elevation [deg]");
      
      const azValue = azColumn[0]?.fields[1].values[0]?.toFixed(2) || 0;
      const elValue = elColumn[0]?.fields[1].values[0]?.toFixed(2) || 0;

      const locationDict: LocationData = { Azimuth: parseFloat(azValue), Elevation: parseFloat(elValue) };

      setScData(locationDict);
    });
  }, [source]); // Effect will re-run if diffSouceName changes

  function processData(dataArray: DataObject[]): ProcessedData {
    const result: ProcessedData = {};
  
    dataArray.forEach(dataObject => {
      const timeField = dataObject.fields.find(field => field.name.toLowerCase() === "_time");
      const cnvValueField = dataObject.fields.find(field => field.name.toLowerCase() === "cnv_value");
      const tpktField = dataObject.fields.find(field => field.name.toLowerCase() === "t_pkt");
      const tInsertField = dataObject.fields.find(field => field.name.toLowerCase() === "t_insert");

      if (timeField && cnvValueField && tpktField && tInsertField) {

        timeField.values.forEach((timeValue, index) => {
          const cnvValue =  cnvValueField.values[index] === null ? "" : cnvValueField.values[index].toFixed(2);
  
          const mne = cnvValueField && cnvValueField.labels ? cnvValueField.labels.mne : "";
  
          if (!result[timeValue]) {
            result[timeValue] = [];
          }

          const name = lookUpName(mne);
  
          result[timeValue].push({ cnvValue, mne, name });
        });
      }
    });
  
    return result;
  }

  const [processedData, setProcessedData] = useState<ProcessedData>({});

  const fetchData = async (query: string, dataSourceName: string) => {
    // Example query incorporating a variable
    const dataSource: DataSourceApi = await getDataSourceSrv().get(dataSourceName);

        // Execute the query and wait for the promise to resolve
        //@ts-ignore
      const result = await dataSource.query({ targets: [{ query: query, refId: 'comQ' }] }).toPromise();

      return result;

  };

  const playBlackFuunction = () => {

    if (guiValues.current.pbisInPlayBack){
      alert('Playback is already in progress. Please stop the current playback before starting a new one.');
      return;
    }

    guiValues.current.pbisInPlayBack = true;

    const mneList = Object.values(nameToMne);

    const mneFilter = mneList.map(mne => `r.mne == "${mne.toLowerCase()}"`).join(' or ');

    // const start = new Date(guiValues.startDateTime).toISOString();
    // const end = new Date(guiValues.endDateTime).toISOString();
    const start = moment.tz(new Date(guiValues.current.startDateTime), 'America/Los_Angeles').utc().format();
    const end = moment.tz(new Date(guiValues.current.endDateTime), 'America/Los_Angeles').utc().format();

    const aggTime = guiValues.current.pbaggTime;
    const aggFn = guiValues.current.pbaggFn.fn;

    //console.log('d', start, end, aggTime, aggFn, locationFilter, source)

    let newSource;
    if (source === "glide_int") {
      newSource = 'glide_int';
    }else if (source === "glide_swtb") {
      newSource = 'glide_swtb';
    }else {
      newSource = 'glide_';
    }

    const exQuery = `from(bucket: "telemetry")
    |> range(start: ${start}, stop: ${end})
    |> filter(fn: (r) =>
        r._measurement == "telemetry" and  
        r.facility == "${newSource}" and
        (${mneFilter})
    )
    |> filter(fn: (r) =>
        r._field == "cnv_value" or r._field == "t_pkt" or r._field == "t_insert"
    )
    |> aggregateWindow(every: ${aggTime}, fn: ${aggFn})
    |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")
  `;

  console.log('exQuery', exQuery);

    fetchData(exQuery, 'INFLUX').then(result => {
      if (result && result.data) { 
        if (result.state === 'Error') {
          alert(`Error: ${result.error.message} (Status: ${result.error.status})`);
        }
        const queryData = result.data; 
        console.log('queryData', queryData);
        const processInfluxData = processData(queryData);
        setProcessedData(processInfluxData);
      }
    });
  } 

  const stopPlayBack = () => {
    guiValues.current['pbisInPlayBack'] = false;
  }

  const guiValues = useRef({
    aggTime: '1s',
    aggFn: { fn: 'last' },
    Agg: false,
    Last: false, 
    lastCount: 50,

    startDateTime: '2024-10-04 11:05:00',
    endDateTime: '2024-10-04 11:06:00',
    pbaggTime: '10s',
    pbaggFn: { fn: 'mean' },
    pbplayBack: playBlackFuunction,
    pbstopPlay: stopPlayBack,
    pbisInPlayBack: false,
  });

  // lil-gui configuration
  const guiContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (guiContainerRef.current) {
      const gui = new dat.GUI({ container: guiContainerRef.current });
      gui.close();
  
      //gui.add(guiValues, 'screenSpacePanning').name('Space Panning');
  
      const f1 = gui.addFolder('Query');
     //f1.add(guiValues, 'startDateTime').name('Start Date Time').listen();
      //f1.add(guiValues, 'endDateTime').name('End Date Time').listen();
  
      const aggController = f1.add(guiValues.current, 'Agg').name('Agg');
      const aggTimeController = f1.add(guiValues.current, 'aggTime').name('Agg Time');
      const aggFnController = f1.add(guiValues.current.aggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      aggFnController.disable();
      aggTimeController.disable();
   
      const lastController = f1.add(guiValues.current, 'Last').name('Last');
      const lastCountController = f1.add(guiValues.current, 'lastCount', 1, 1000, 1).name('Last Count');
      lastCountController.disable();
  
      aggController.onChange((value: boolean) => {
        if (value) {
          aggTimeController.enable();
          aggFnController.enable();
        } else {
          aggTimeController.disable();
          aggFnController.disable();
        }
      });
  
      lastController.onChange((value: boolean) => {
        if (value) {
          lastCountController.enable();
        } else {
          lastCountController.disable();
        }
      });

      const f2 = gui.addFolder('Playback (PT)');
      f2.add( guiValues.current, 'startDateTime').name('Start Date Time');
      f2.add( guiValues.current, 'endDateTime').name('End Date Time');
      f2.add( guiValues.current, 'pbaggTime').name('Agg Time');
      f2.add(guiValues.current.pbaggFn, 'fn', ['last', 'mean', 'sum', 'count']).name('Agg Fn');
      //f1.add( guiValues, 'loadingPercent', 0,100, 1).name('Loading %').disable();
      f2.add( guiValues.current, 'pbplayBack').name('Playback');
      f2.add( guiValues.current, 'pbstopPlay').name('Stop Playback');

      f1.close();
      f2.close();
      if (!guiValues.current.pbisInPlayBack) { 
        setProcessedData({});
      }
      
    }
  }, [guiValues]);

    // Extract the time range as sa
    const timeRange = {
      from: data.timeRange.from, 
      to: data.timeRange.to
    };

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div       
    className={cx(
      styles.wrapper,
      css`
        width: ${width}px;
        height: ${height}px;

        .lil-gui.root{
          --background-color: #d29d00;
          --font-size: 11px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1000;
          --background-color: #002b36;
          --text-color: #b2c2c2;
          --title-background-color: #001f27;
          --title-text-color: #b2c2c2;
          --widget-color: #094e5f;
          --hover-color: #0a6277;
          --focus-color: #0b6c84;
          --number-color: #2aa0f2;
          --string-color: #97ad00;
          --folder-indent: 100px;
          --widget-border-radius: 0px;
        }
      `
    )}>
    <TimeRangeContext.Provider value={{ timeRange, guiValues }}>
    <div ref={guiContainerRef} />

        <AppNew dbData={selectedData} source={source} width={width} height={height} options={options} influxData={processedData} groundData={groundData} locationData={scData}></AppNew>

        </TimeRangeContext.Provider>

  </div>
   ); 
};
