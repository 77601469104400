import React from 'react'
import {InnerData, TLM} from './utils';
import { Handle, Position } from 'reactflow';

interface ScSketchProp{
  data: {
    name: string;
    value: InnerData;
    select: TLM;
  }
}


const ScSketch: React.FC<ScSketchProp> = ({data}) => {

    const getColor = (name: string) => {
        if (data && data.select && data.select.telemetry){
          if (data.select.telemetry.toLowerCase().includes(name.toLowerCase())) {
            return "green"; // Green for the selected and ON disk
          }
          else{
            return "#404040";
          }
        }else{
          return "#404040";
        }
      }

  return (
    <div>

      <Handle type="target" position={Position.Top} id="target-top" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left" style={{opacity:0}}/>
      <Handle type="target" position={Position.Left} id="target-left-1" style={{top:250, opacity:0}}/>
      <Handle type="target" position={Position.Right} id="target-right" style={{opacity:0}}/>
      <Handle type="target" position={Position.Bottom} id="target-bottom" style={{opacity:0}}/>
      
      <Handle type="source" position={Position.Top} id="source-top" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left" style={{opacity:0}}/>
      <Handle type="source" position={Position.Left} id="source-left-1" style={{opacity:0, top:500}}/>
      <Handle type="source" position={Position.Right} id="source-right" style={{opacity:0}}/>
      <Handle type="source" position={Position.Bottom} id="source-bottom" style={{opacity:0}}/>

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="500px" height="410px" viewBox="-0.5 -0.5 500 410">
            <defs/>
              <g > {/* Rotate the whole SVG transform="rotate(45 150 150)" */}
                <g>
                <polygon points="5,5 495,5 495,200 400,405 100,405 5,200" fill="#606060" stroke="rgb(13,39,45)" strokeWidth="6"/>
                </g>

                <g>
                  <rect x="305" y="305" width="60" height="60" fill={getColor('lga2')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="310" y="330" fill="white" fontSize="25px">Z-</text>
                  <text x="310" y="360" fill="white" fontSize="25px">LGA</text>
                </g>
            </g>

            <text x="200" y="220" fill="white" fontSize="45px">+ Y</text>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="400px" height="360px" viewBox="-0.5 -0.5 400 360">
            <defs/>

              <g > {/* Rotate the whole SVG transform="rotate(45 150 150)" */}
                <g>
                  <rect x="5" y="5" width="390" height="300" fill="#606060" stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                </g>
                <g>
                  <rect x="275" y="195" width="95" height="95" fill={getColor('mga')} stroke="rgb(13,39,45)" strokeWidth="6" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="281" y="238" fill="white" fontSize="45px">MGA</text>
                  <text x="296" y="275" fill="white" fontSize="45px">Tx</text>
                </g>

                <g>
                  <rect x="35" y="225" width="60" height="60" fill={getColor('lga2')} stroke="rgb(13,39,45)" strokeWidth="5" strokeMiterlimit="10" pointerEvents="all"/>
                  <text x="40" y="255" fill="white" fontSize="25px">N-</text>
                  <text x="40" y="280" fill="white" fontSize="25px">LGA</text>
                </g>

            </g>

            <text x="145" y="170" fill="white" fontSize="45px">- Y</text>
            </svg>
                  </div>

    </div>
  );
}

export default ScSketch;
