import copy from 'copy-to-clipboard';

export type InnerDataValue = {
  telemetry: string;
  unit: string;
  limit: LimitProp;
  fname: string;
  mname: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export type VisibleTooltips = { [key: string]: boolean };


export interface Dbdata{
  telemetry: string;
  unit: string;
  //limit: LimitProp;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      className: string;
      id: string;
      backgroundImage: string;
      power: TLM;
      power1: TLM;
      powerName: string;
      power1Name: string;
      spocAV: TLM;
      spocBV: TLM;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export type LocationData = {
  Azimuth: number | null;
  Elevation: number | null;
};

export type Field = {
  name: string;
  type: string;
  labels?: { [key: string]: string };
  values: any[]; // Replace 'any' with a more specific type if possibl
};

export interface ProcessedData {
  [key: string]: Array<{ cnvValue: string, mne: string, name: string }>;
}

export interface DataObject {
  name: string;
  refId: string;
  length: number;
  fields: Field[];
  labels: { [key: string]: string };
}

export function lookUpName(mne: string) {
  for (const [key, value] of Object.entries(nameToMne)) {
    if (value === mne) {
      return key;
    }
  }
  return "NoName"; // Return null if the value is not found
}

export function determineColorNew(name: string){
  if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
    return 'red-limit';
  }
  else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
      return 'yellow-limit';
  }else{
    return 'gray-limit';
  }
}

export const nameToMne = {

  // EPS - Electrical Power System
  "Battery Curr":"pwbi", /* Battery current */
  "Battery Temp":"pwbt", /* Battery temperature */
  "Battery Volt":"pwbv", /* Battery voltage */
  "Battery Curr SACI":"pwsacibi", /* Battery Current from SACI */
  "Battery Volt SACI":"pwsacibv", /* Battery Voltage from SACI */

  "SA Current":"pwsacisai", /* SA Current */
  "SA1 Pwr":"pwsa1pw", /* SA Switch Status: 1 */
  "SA2 Pwr":"pwsa2pw", /* SA Switch Status: 2 */
  "SA3 Pwr":"pwsa3pw", /* SA Switch Status: 3 */
  "SA4 Pwr":"pwsa4pw", /* SA Switch Status: 4 */
  "SA5 Pwr":"pwsa5pw", /* SA Switch Status: 5 */
  "SA6 Pwr":"pwsa6pw", /* SA Switch Status: 6 */
  "SA7 Pwr":"pwsa7pw", /* SA Switch Status: 7 */
  "SA8 Pwr":"pwsa8pw", /* SA Switch Status: 8 */
  "SA9 Pwr":"pwsa9pw", /* SA Switch Status: 9 */
  "SA10 Pwr":"pwsa10pw", /* SA Switch Status: 10 */
  "SA11 Pwr":"pwsa11pw", /* SA Switch Status: 11 */
  "SA12 Pwr":"pwsa12pw", /* SA Switch Status: 12 */

  "Brb K1k2 Coil State":"brb1coilstate", /* K1K2 Coil State */
  "Brb Sep Rly1 State":"brbrly1state", /* SEP_RLY1_STATE */
  "Brb Sep Rly2 State":"brbrly2state", /* SEP_RLY2_STATE */
  "Brb Temp":"t27brbt", /* Temp, Battery Relay */

  // CDH
  "SSMB MBE Err Cnt B1":"ssmbmbecntb1", /* MBE Error Count from memory Bank 1 of SDRAM */
  "SSMB MBE Err Cnt B2":"ssmbmbecntb2", /* MBE Error Count from memory Bank 2 of SDRAM */
  "SSMB MBE Err Cnt B3":"ssmbmbecntb3", /* MBE Error Count from memory Bank 3 of SDRAM */
  "SSMB SBE Err Cnt B1":"ssmbsbecntb1", /* SBE Error Count from memory Bank 1 of SDRAM */
  "SSMB SBE Err Cnt B2":"ssmbsbecntb2", /* SBE Error Count from memory Bank 2 of SDRAM */
  "SSMB SBE Err Cnt B3":"ssmbsbecntb3", /* SBE Error Count from memory Bank 3 of SDRAM */

  "SMOAB WatchDog ISR Task Status":"swwdmoabisrt", /* Watchdog MOAB ISR Task Status */
  "SMOAB WatchDog MBE Task Status":"swwdmoabmbet", /* Watchdog MOAB MBE Task Status */
  "SMOAB WatchDog SBE Task Status":"swwdmoabsbet", /* Watchdog MOAB SBE Task Status */
  "SMOAB WatchDog TLM Task Status":"swwdmoabtlmt", /* Watchdog MOAB Telemetry Task Status */

  // CDH - RAD750
  "FSW Cmd Acpt Cnt": "swacpt", /* FSW Command Accept Counter */
  "CRC Acpt Cnt":"swcrcacpt", /* Table CRC Accept Counter */
  "CRC Rj Cnt":"swcrcrjct", /* Table CRC Reject Counter */
  "Launch Mode Bit1":"swclaunch1", /* Current Cfg - Launch Mode Bit One */
  "Launch Mode Bit2":"swclaunch2", /* Current Cfg - Launch Mode Bit Two */
  "PBK Critical VC":"swpbcvc", /* PBK Critical VC */
  "PT Critical VC":"swrtcvc", /* RT Critical VC */
  "":"", /*  */

  // ADCS
  "RW1 Pwr": "adrw1pw", /* Reaction Wheel 1 Power*/
  "RW2 Pwr": "adrw2pw", /* Reaction Wheel 2 Power*/
  "RW3 Pwr": "adrw3pw", /* Reaction Wheel 3 Power*/
  "RW4 Pwr": "adrw4pw", /* Reaction Wheel 4 Power*/

  "RW1 Speed/dir": "adrw1sdir", /* Reaction Wheel 1 Speed/Dir*/
  "RW2 Speed/dir": "adrw2sdir", /* Reaction Wheel 1 Speed/Dir*/
  "RW3 Speed/dir": "adrw3sdir", /* Reaction Wheel 1 Speed/Dir*/
  "RW4 Speed/dir": "adrw4sdir", /* Reaction Wheel 1 Speed/Dir*/

  "SS1 Intensity": "adss1int", /* Sun Sensor 1 Intensity*/
  "SS2 Intensity": "adss2int", /* Sun Sensor 2 Intensity*/
  "SS3 Intensity": "adss3int", /* Sun Sensor 3 Intensity*/
  "SS4 Intensity": "adss4int", /* Sun Sensor 4 Intensity*/
  "SS5 Intensity": "adss5int", /* Sun Sensor 5 Intensity*/
  "SS6 Intensity": "adss6int", /* Sun Sensor 6 Intensity*/
  "SS7 Intensity": "adss7int", /* Sun Sensor 7 Intensity*/
  "SS8 Intensity": "adss8int", /* Sun Sensor 8 Intensity*/
  "SS9 Intensity": "adss9int", /* Sun Sensor 9 Intensity*/
  "SS10 Intensity": "adss10int", /* Sun Sensor 10 Intensity*/
  "SS11 Intensity": "adss11int", /* Sun Sensor 11 Intensity*/
  "SS12 Intensity": "adss12int", /* Sun Sensor 12 Intensity*/

  /* ADCS - star trackers */
  "St Pwr": "STPW", /* Star Tracker Power */
  "St Mode": "stmode", /* Star Tracker Mode */

  /* ADCS - IRU */
  "IRU Pwr": "irupw", /* IRU Switched Power */
  "IRU Gryo Est Status": "adrsbiasst", /* Gyro Bias Estimation Status */
  "IRU Sensor Good": "adirugood", /* Rate Sensor Good Throughout */

  // COMM
  "Rx Mode": "rxopmodest", /*Receiver Operating Mode !*/
  "Command Decrypt": "rxaescmd", /*Command Decrypt/Authenticate Error !*/

  "Transmitter Pwr": "nbxtxpw", /*Measurement of the transmitter power !*/
  "Coherency Enabled": "nbxcohst", /*Coherency Enabled !*/ 
  "RF Enable": "nbxrfst", /*Status of RF Enable command !*/

  "TX Installed": "nbxarmplugst", /*TX Group ENBL PLUG INSTALLED  !*/
  "Init Rout Status": "nbxinitdone", /* */
  "Init Rout Result": "nbxinitresult", /* */
  "Init Result": "nbxad9364calst", /* */

  "Signal Detected": "nbxsigdetect", /*Signal has been detected*/
  "Loop Stress": "rxloop", /*Loop Stress*/
  "Loop Signal power": "rxss", /*Signal power in the carrier tracking loop*/
  "Rx loop lock": "rxlock", /*Receiver carrier loop is locked !*/
  "Detector Lock": "rxbslock", /*Receiver Bit-Sync !*/
  "Bit-Sync Lock": "nbxbslock", /*XCVR_UPLINK_SYNC_LOCK*/
  "Cnd DS":"SWTFACPT", /* FSW Transfer Frame Accept Counter .This is counter of the commands successfully decoded by the radio and sent to the C&DH. */

  // PROP
  "Prop N2H4 Temp":"t36tankn2h4t", /* Tank N2H4 Spacecraft Temp */
  "Fill Drain T":"t42filldrainvalven2h4t", /* Temp, Prop Fill/Drain Line N2H4 */
  "Prop GN2 Temp":"t37tankgn2t", /* PROP Tank GN2 Spacecraft Temp */
  "Thruster T1":"adt1firet", /* Thruster 1 Fire */
  "Thruster T2":"adt2firet", /* Thruster 2 Fire */
  "Thruster T3":"adt3firet", /* Thruster 3 Fire */
  "Thruster T4":"adt4firet", /* Thruster 4 Fire */

  // Payload
  "NFI HV": "iop_hv_nfi",
  "NFI Temp": "iop_nfi_hv_temp",
  "NFI HV13 VMon": "iop_nfi_hv13_vmon",

  "WFI HV": "iop_hv_wfi",
  "WFI Temp": "iop_wfi_hv_temp",
  "WFI HV13 VMon": "iop_wfi_hv13_vmon",

  "NFI Cam Pwr": "iop_nfi_cam_pwr",
  "NFI Image State": "iop_nfi_img_state",
  "NFI Cam IMon": "iop_nfi_cam_pwr_imon",

  "WFI Cam Pwr": "iop_wfi_cam_pwr",
  "WFI Image State": "iop_wfi_img_state",
  "WFI Cam IMon": "iop_wfi_cam_pwr_imon",

  "NFI Filter Position": "iop_motor_fw_pos_nfi",
  "NFI Fw Temp": "iop_nfi_fw_temp",
  "NFI Motor Busy": "iop_motor_busy_nfi",

  "WFI Filter Position": "iop_motor_fw_pos_wfi",
  "WFI Fw Temp": "iop_wfi_fw_temp",
  "WFI Motor Busy": "iop_motor_busy_wfi",

  "NFI Opt 1 Temp": "iop_nfi_optics_temp1",
  "NFI Opt 2 Temp": "iop_nfi_optics_temp2",

  "WFI Opt 1 Temp": "iop_wfi_optics_temp1",
  "WFI Opt 2 Temp": "iop_wfi_optics_temp2",


  // TMON

  // TMON - Over Voltage - 1
  "TMon 03 Triggered":"swtm003t", /* TMON 003 Triggered */

  "TMon 05 Triggered":"swtm005t", /* TMON 005 Triggered */
  "TMon 07 Triggered":"swtm007t", /* TMON 007 Triggered */
  "TMon 08 Triggered":"swtm008t", /* TMON 008 Triggered */

  "TMon 09 Triggered":"swtm009t", /* TMON 009 Triggered */
  
  "TMon 28 Triggered":"swtm028t", /* TMON 028 Triggered */
  "TMon 29 Triggered":"swtm029t", /* TMON 029 Triggered */
  "TMon 30 Triggered":"swtm030t", /* TMON 030 Triggered */
  "TMon 31 Triggered":"swtm031t", /* TMON 031 Triggered */
  "TMon 32 Triggered":"swtm032t", /* TMON 032 Triggered */
  "TMon 33 Triggered":"swtm033t", /* TMON 033 Triggered */
  "TMon 34 Triggered":"swtm034t", /* TMON 034 Triggered */
  "TMon 35 Triggered":"swtm035t", /* TMON 035 Triggered */
  
  "TMon 43 Triggered":"swtm043t", /* TMON 043 Triggered */
  "TMon 53 Triggered":"swtm053t", /* TMON 053 Triggered */
  "TMon 56 Triggered":"swtm056t", /* TMON 056 Triggered */

  "TMon 59 Triggered":"swtm059t", /* TMON 059 Triggered */

  "TMon 64 Triggered":"swtm064t", /* TMON 064 Triggered */
  "TMon 65 Triggered":"swtm065t", /* TMON 065 Triggered */
  "TMon 66 Triggered":"swtm066t", /* TMON 066 Triggered */

  "TMon 70 Triggered":"swtm070t", /* TMON 070 Triggered */
  "TMon 71 Triggered":"swtm071t", /* TMON 071 Triggered */
  "TMon 72 Triggered":"swtm072t", /* TMON 072 Triggered */

  "TMon 170 Triggered":"swtm089t", /* TMON 170 Triggered */
  "TMon 171 Triggered":"swtm171t", /* TMON 171 Triggered */
  "TMon 172 Triggered":"swtm172t", /* TMON 172 Triggered */
  "TMon 173 Triggered":"swtm173t", /* TMON 173 Triggered */
  
  "":"", /*  */
  "":"", /*  */
  "":"", /*  */
  "":"", /*  */


}

export const checkOutOfLimitsEx = (data: InnerData) => {
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `rgb(246, 80, 80)`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#CA51EC`;
  }else {
    return `black`;
  }
};

export const checkOutOfLimitsHid = (data: InnerData) => {
  //console.log(data);
  let sawRed = false, sawYellow = false, sawLive = false, sawWithin12 = false;
  Object.entries(data).forEach(([key, innerData]) => {
    if (innerData && innerData.dbData && innerData.dbData?.limit) {
      const limitClass = determineColorNew(innerData.dbData?.limit);
      if (limitClass === 'red-limit') {
        sawRed = true;
      }
      else if (limitClass === 'yellow-limit') {
        sawYellow = true; 
      }
    }
    else if (innerData.dbData?.live === 'rgb(72, 200, 44)') {
      sawLive = true;
    }
    else if (innerData.dbData?.live === '#CA51EC') {
      sawWithin12 = true;
    }
  });
  if (sawRed) {
    return `#d10808`;
  }
  else if (sawYellow) {
    return `rgb(230, 230, 65)`;
  }
  else if (sawLive) {
    return `rgb(72, 200, 44)`;
  }
  else if (sawWithin12) {
    return `#A020F0`;
  }
  else {
    return `gray`;
  }
};

export const handleCopyMneName = (mne: string) => {
  copy(mne);
};


